@tailwind base;
@tailwind components;
@tailwind utilities;




input:checked ~ .dot {
  transform: translateX(150%);
  background-color: #ffffff;
}

input:checked ~ .block {
  background-color: #fd6b24;
  border: none;
}
